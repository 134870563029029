import {BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Shop from "./pages/shop/Shop";
import Servers from "./pages/servers/Servers";
import Rules from "./pages/rules/Rules";
import About from "./pages/about/About";
import Navbar from "./components/Navbar";

export const ReactRoutes = () => {
    return (
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path={"/home"} element={<Home/>}/>
                <Route path={"*"} element={<Home/>}/>

                <Route path={"/About"} element={<About/>}/>
                <Route path={"/Servers"} element={<Servers/>}/>
                <Route path={"/Shop"} element={<Shop/>}/>

                <Route path={"/Rules"} element={<Rules/>}/>
            </Routes>
        </BrowserRouter>
    )
}
