import React from 'react';
import './Home.scss';

function Home() {
    return (
        <div className={"home parent-page flex-parent"}>
            <h1 className={"flex-child main-text main-color"}>Plyblox Network</h1>
        </div>
    );
}

export default Home;
