import React from 'react';
import './Shop.scss';

function Shop() {
    return (
        <div className={"shop parent-page flex-parent"}>
            <h1 className={"flex-child main-text main-color"}>Shop</h1>

            <p className={"sub-text sub-color"}>Click <a className={"hover-check"} href="https://store.plyblox.com" target={"_blank"} rel={"noreferrer"}>here</a> to view or store!</p>
        </div>
    );
}

export default Shop;
