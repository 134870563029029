import React from 'react';
import './Rules.scss';

function Rules() {
    return (
        <div className={"rules parent-page flex-parent"}>

            <iframe title={"rules"}
                src="https://docs.google.com/document/d/e/2PACX-1vSuqCmlN124gFfI8GC44H7wKJaSc2VwrcigcjwQjMg_B1qH7E0iYlzW9I_zAKKHN7rFrn11g64Yl5CC/pub?embedded=true"></iframe>

        </div>
    );
}

export default Rules;
