import React from 'react';
import './Servers.scss';

function Servers() {
    return (
        <div className={"servers parent-page flex-parent"}>
            <h1 className={"flex-child main-text main-color"}>Servers - WIP</h1>
        </div>
    );
}

export default Servers;
