import React from 'react';
import '../styles/Copyright.scss';

function Copyright() {
    return (
        <footer>
            <p className={"sub-color tiny-text slight-vertical-margin"}>
                Copyright ©{new Date().getFullYear()} - All rights reserved. | <a className={"title-color hover-check"}
                            href={"/"}>Plyblox Network</a>.
            </p>
        </footer>
    );
}

export default Copyright;
