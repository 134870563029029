import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/Main.scss';
import reportWebVitals from './reportWebVitals';
import {ReactRoutes} from "./ReactRouter";
import Copyright from "./components/Copyright";

import backgroundImage from "./images/background.gif";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <div className={"website-container"}>
          <div className={"background"}>
            <img src={backgroundImage} alt={"background gif"}/>
          </div>
          <ReactRoutes />
          <Copyright />
      </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
