import React from 'react';
import '../styles/Navbar.scss';
import {Link} from "react-router-dom";

function Navbar() {
    return (
        <div className={"flex-parent navbar"}>
            <h1 className={"main-text main-color logo"}>
                <Link className={"logo_link"} to={"/Home"}>Plyblox</Link>
            </h1>

            <div className={"flex-child flex-parent navbar_list"}>
                <ul className={"flex-child list-hide flex-parent"}>
                    <li className={"flex-child"}>
                        <Link className={"sub-text sub-color hover-check"} to={"/Home"}>Home</Link>
                    </li>

                    <li className={"flex-child"}>
                        <Link className={"sub-text sub-color hover-check"} to={"/About"}>About</Link>
                    </li>

                    <li className={"flex-child"}>
                        <Link className={"sub-text sub-color hover-check"} to={"/Servers"}>Servers</Link>
                    </li>

                    <li className={"flex-child"}>
                        <Link className={"sub-text sub-color hover-check"} to={"/Shop"}>Shop</Link>
                    </li>

                    <li className={"flex-child"}>
                        <Link className={"sub-text sub-color hover-check"} to={"/Rules"}>Rules</Link>
                    </li>
                </ul>

            </div>
        </div>
    );
}

export default Navbar;
