import React from 'react';
import './About.scss';

function About() {
    return (
        <div className={"about parent-page flex-parent"}>
            <h1 className={"flex-child main-text main-color"}>About - WIP</h1>
        </div>
    );
}

export default About;
